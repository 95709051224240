import React from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getGlobal } from "@/reducers/states";
import layoutPlugins from "@/plugins";
import { SentryRoutes } from "@/utils/sentry.util";

const Children = (routes: any) => {
  return (
    <>
      {routes.map((route: any) => {
        const RouteComponent: any = route.element;
        return (
          <React.Fragment key={route.path}>
            {route.redirect && (
              <Route
                path={route.path}
                element={<Navigate to={route.redirect} />}
              />
            )}
            <Route
              children={Children(route.children)}
              path={route.path}
              element={<RouteComponent routes={route.children} />}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

const Router = (props: { routes: any; appConfig: any }) => {
  const { routes, appConfig } = props;
  const location = useLocation();
  const NotFound = routes.find((route: any) => route.path === "/404").element;
  const global = useSelector(getGlobal);

  // url should include /oauth/login or /oauth/callback or /oauth/account/settings or /oauth/google or /oauth/facebook or /oauth/line or /oauth/apple or /oauth/open/:provider
  const isOauthPage =
    /\/oauth\/(login|open|callback|account\/settings|google|facebook|line|apple)/.test(
      location.pathname
    );

  const { search } = location;

  return (
    <SentryRoutes>
      {routes
        .filter((route: any) => route.path !== "/404")
        .map((route: any) => {
          const RouteComponent: React.FunctionComponent<any> = route.element;
          const layoutName = route.isRoot
            ? route.componentPath.replace(/layout\//i, "")
            : "";
          const componentPlugins = layoutPlugins[layoutName];

          if (
            route.path !== "/user" &&
            !isOauthPage &&
            appConfig.enableVerifyToken.value &&
            !global.user.loaded &&
            !route.skipAuth
          ) {
            return (
              <React.Fragment key={route.path}>
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={{
                        pathname: "/user/signin",
                        search,
                      }}
                    />
                  }
                />
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={route.path}>
              {route.redirect && (
                <Route
                  path={route.path}
                  element={
                    <Navigate
                      to={{
                        pathname: route.redirect,
                        search,
                      }}
                    />
                  }
                />
              )}
              <Route
                children={
                  <>
                    {Children(route.children)}{" "}
                    <Route path="*" element={<NotFound />} />
                  </>
                }
                path={route.path}
                element={
                  <RouteComponent
                    routes={route.children}
                    componentPlugins={componentPlugins}
                  />
                }
              />
            </React.Fragment>
          );
        })}
    </SentryRoutes>
  );
};

export default Router;
